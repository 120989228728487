<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <c-card title="Archivos" class="overflow-auto">
          <div>
            <span v-for="file in files" :key="file.id" class="ms-2">
              <a
                :href="file.document"
                target="_blank"
                type="button"
                class="btn badge btn-primary rounded"
              >
                {{ file.name }}
              </a>
              <button
                @click="removeFile(file.id)"
                class="badge bg-secondary border-0 rounded-start-0"
              >
                x
              </button>
            </span>
          </div>
          <hr v-if="files.length" />
          <form ref="formFile">
            <div class="mb-3">
              <label for="file" class="form-label">Cargar archivo</label>
              <input
                @change="uploadFile($event)"
                class="form-control form-control-sm"
                id="file"
                type="file"
              />
            </div>
          </form>
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import { files, createFiles } from "@/services/tools";
import { remove } from "@/services/files_tools";
import { showNotification } from "@/services/notification";
export default {
  components: {
    CCard,
  },
  data() {
    return {
      toolId: this.$route.params.toolId,
      files: [],
    };
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    getFiles() {
      files(this.toolId).then((response) => {
        this.files = response.data;
      });
    },
    uploadFile(event) {
      const document = event.target.files[0];
      let formData = new FormData();
      formData.append("file", document);
      createFiles(this.toolId, formData).then(() => {
        showNotification(
          204,
          "success",
          "El archivo fue guardado exitosamente."
        );
        this.$refs.formFile.reset();
        this.getFiles();
      });
    },
    removeFile(id) {
      remove(id).then(() => {
        showNotification(
          204,
          "success",
          "El archivo fue eliminado exitosamente."
        );
        this.getFiles();
      });
    },
  },
};
</script>
