<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <c-card title="Imagen principal" class="overflow-auto mb-3">
          <c-422-errors :errors="mainImageErrors" />
          <span>
            <a
              :href="tool.main_image_link"
              target="_blank"
              type="button"
              class="btn badge btn-primary rounded"
            >
              {{ tool.main_image }}
            </a>
          </span>
          <form ref="formMainImage">
            <div class="mb-3">
              <label for="mainImage" class="form-label"
                ><span class="mt-2 d-inline-block">{{
                  tool.main_image ? "Editar" : "Cargar"
                }}</span>
                imagen principal</label
              >
              <input
                @change="uploadMainImage($event)"
                class="form-control form-control-sm"
                id="mainImage"
                type="file"
                accept="image/*"
              />
            </div>
          </form>
        </c-card>
        <c-card title="Imágenes" class="overflow-auto">
          <c-422-errors :errors="errors" />
          <div>
            <span v-for="image in images" :key="image.id" class="ms-2">
              <a
                :href="image.image_link"
                target="_blank"
                type="button"
                class="btn badge btn-primary rounded"
              >
                {{ image.name }}
              </a>
              <button
                @click="removeImage(image.id)"
                class="badge bg-secondary border-0 rounded-start-0"
              >
                x
              </button>
            </span>
          </div>
          <hr v-if="images.length" />
          <form ref="formImage">
            <div class="mb-3">
              <label for="image" class="form-label">Cargar imagen</label>
              <input
                @change="uploadFile($event)"
                class="form-control form-control-sm"
                id="image"
                type="file"
                accept="image/*"
              />
            </div>
          </form>
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import C422Errors from "@/components/C422Errors";
import { find, images, createMainImage, createImages } from "@/services/tools";
import { remove } from "@/services/images_tools";
import { showNotification } from "@/services/notification";
export default {
  components: {
    CCard,
    C422Errors,
  },
  data() {
    return {
      toolId: this.$route.params.toolId,
      tool: {
        main_image: null,
        main_image_link: null,
      },
      images: [],
      mainImageErrors: {},
      errors: {},
    };
  },
  mounted() {
    this.getImages();
    this.getTool();
  },
  methods: {
    getTool() {
      find(this.toolId).then((response) => {
        this.tool = response.data;
      });
    },
    getImages() {
      images(this.toolId).then((response) => {
        this.images = response.data;
      });
    },
    uploadFile(event) {
      const image = event.target.files[0];
      let formData = new FormData();
      formData.append("image", image);
      createImages(this.toolId, formData)
        .then(() => {
          showNotification(
            204,
            "success",
            "El archivo fue guardado exitosamente."
          );
          this.$refs.formImage.reset();
          this.getImages();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    uploadMainImage(event) {
      const image = event.target.files[0];
      let formData = new FormData();
      formData.append("main_image", image);
      createMainImage(this.toolId, formData)
        .then(() => {
          showNotification(
            204,
            "success",
            "El archivo fue guardado exitosamente."
          );
          this.getTool();
          this.$refs.formMainImage.reset();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.mainImageErrors = error.response.data.errors;
          }
        });
    },
    removeImage(id) {
      remove(id).then(() => {
        showNotification(
          204,
          "success",
          "El archivo fue eliminado exitosamente."
        );
        this.getImages();
      });
    },
  },
};
</script>
